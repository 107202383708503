import React from "react"
//import PropTypes from "prop-types"

import "../styles.scss"
import infoStyles from "./no-code-info.module.scss"

class InfoNoCode extends React.Component {


    constructor(props) {
        super(props);
        this.state = { showInfo: false };

    }

    toggleShowHide = () => {
        //this.setState(prevState => { showInfo: !prevState.showMenu })
        const currentState = this.state.showInfo;
        this.setState({ showInfo: !currentState });
        console.log('toggleShowHide: ' + currentState);
    }


    render() {

        return (
            <div>
                <button className="btn btn-secondary btn-block" onClick={this.toggleShowHide}>
                    Ikke mottatt kode?
        </button>
                {this.state.showInfo ?
                    <div id="noCodeInfo" className={`${infoStyles.landingpageNotReceivedCode}`}  >
                        <button id="closeButton" type="button" className={`close btn-lg ${infoStyles.closeButton}`}  aria-label="Close" onClick={this.toggleShowHide}>
                            <span className={`${infoStyles.closeButton}`}   aria-hidden="true">&times;</span>
                        </button>

                        <div className={`${infoStyles.content}`}  >
                            <div >
                                <h3>Ikke mottatt din katalog kode?</h3>
                                <p>Alle som er har vært på en leirskole som samarbeider med Leirskoledagboken AS skal motta informasjon om
                                    innlogging snarest mulig etter fullført
                                    leirskole uke. Innlogging skjer enten via en kode eller en link som mottas via E-post, SMS eller brev.
                                    </p>
                                <p>Har du ikke mottatt din katalog kode eller kataloglink? Kontakt oss på <a
                                    href='mailto:kundeservice@leirskoledagboken.no'>kundeservice@leirskoledagboken.no</a> så hjelper vi
                                    deg med å få tilgang til å bestille katalogen din!</p>
                            </div>
                        </div>

                    </div>
                    : null}
            </div>)
    }

}

export default InfoNoCode
