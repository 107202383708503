import React from "react"

import LayoutLanding from "../components/layout-landing"
import Info from "../components/info"
import NoCodeInfo from "../components/no-code-info"
import ClientOnly from "../components/client-only"
import SEO from "../components/seo"


class IndexPage extends React.Component {


  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);

    if(!params){
      console.error('Could not create URLSearchParams on index page');
    }

    const errorCode = params ? params.get("error") : '';
    this.state = { error: errorCode };
  }

  render() {

    return (

      <LayoutLanding>
        <SEO title="Bestill leirskoledagbok"
          description="Bestill leirskoledagboken fra ditt opphold og ta vare på de gode minnene."
          pagePath="/" />

            <h1>Bestill leirskoledagboken</h1>
            <div className="codetext">Har du mottatt en kode fra oss? </div>

            <form action="/r/" method="get">
              <ClientOnly>
                {
                  this.state.error === 'invalid-key' ?
                    <div className="alert alert-danger" role="alert">Ugyldig katalog kode</div>
                    : null
                }
              </ClientOnly>
              <ClientOnly>
                {
                  this.state.error === 'error' ?
                    <div className="alert alert-danger" role="alert">Noe gikk feil når vi så etter koden din. Vennligst prøv igjen og kontakt kundeservice hvis det fortsetter.</div>
                    : null
                }
              </ClientOnly>
              <ClientOnly>
                {this.state.error === 'invalid-confirmation' ?
                  <div className="alert alert-danger" role="alert">Noe gikk galt når vi skulle vise bekreftelses siden.</div>
                  : null
                }
              </ClientOnly>
              <div className="form-group">
                <input name="c" type="text" className="form-control form-control-lg" aria-label="Skriv inn koden du har fått på epost eller sms her" placeholder="Skriv inn koden din her" />
              </div>
              <div className="form-group">
                <button id="checkCode" className="btn btn-primary btn-lg btn-block" >Fortsett</button>
              </div>
            </form>
            <NoCodeInfo></NoCodeInfo>
            <Info></Info>
      </LayoutLanding>
    )
  }
}

export default IndexPage
