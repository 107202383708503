/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../styles.scss"
import landingStyles from "./layout-landing.module.scss"
import logo from "./../images/logo.jpg"
import Helmet from 'react-helmet'

const LayoutLanding = ({ children }) => {

  return (

     <div className={`${landingStyles.contentContainer}`} >

<Helmet
    bodyAttributes={{
        class: 'layout-body'
    }}
/>

      <div className={`${landingStyles.content}`} >
        <main className={`card text-center ${landingStyles.workspace} col-xs-12 col-sm-12 col-md-11 col-lg-9 col-xl-7 mx-auto  pl-0 pl-sm-1 pl-md-2 pr-0 pr-sm-1 pr-md-2`} >
          <img className={`card-img-top ${landingStyles.logo} pt-0 pt-sm-1 pt-md-4 `} src={logo} alt="Leirskoledagboken AS sin logo" />
          <div className="card-body  pl-0 pl-sm-1 pr-0 pr-sm-1">
            {children}
          </div>
        </main>
      </div>

      <footer className={`${landingStyles.footer} fixed-bottom`}>
        <div className="container">
          <div className="d-flex justify-content-center">
            <a href="/about/" ><span className="">For mer informasjon – se her. </span> <span className="d-none d-md-inline">Leirskoleskoledagboken AS – Tlf 91 00 13 95</span></a>
          </div>
        </div>
      </footer>
    </div >

  )
}

LayoutLanding.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLanding
