import React, { Component } from 'react';
//import PropTypes from "prop-types"
import "../styles.scss";
import infoStyles from "./info.module.scss";

class Info extends Component {
    render() {
        return <div className={`card ${infoStyles.info}`}>

            <h2 className="card-title"> Om Leirskoledagboken</h2>
            <div className="row">
                <div className="col">
                    {this.props.productImageUrl ?
                        <div>
                            <img className={`${infoStyles.productImage} rounded float-right d-none d-sm-block `} alt="Bilde fra leirskolen" src={this.props.productImageUrl}></img>
                        </div>
                        :
                        null
                    }
                    <ul>
                        <li>Håndplukkede bilder fra leirskoleoppholdet</li>
                        <li>Leirskolestemning med bla. elevenes egne bilder</li>
                        <li>Få boken hjem, se på den i ro og mak</li>
                        <li>Pris {this.props.productPrice ? this.props.productPrice : 419},- totalt inkl. frakt. </li>
                        <li>Fri returrett, med lang returfrist</li>
                        <li>Miljøsmart produksjon - klimakompensert</li>
                        <li>A4-format, inntil 72 sider</li>
                    </ul>
                    <span>NB! Boken vises ikke på nett, til gjengjeld har du 30 dagers angrerett.</span>
                    
                    {this.props.productImageUrl ?
                        <div className="row d-flex justify-content-center">
                            <img className={` img-fluid rounded d-block d-sm-none w-75`} alt="Bilde fra leirskolen" src={this.props.productImageUrl}></img>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>;
    }
}

export default Info;
